import React,{useState,useEffect,useRef} from "react"
import ReactDOM from 'react-dom'

const SearchPanel = (props) => {
  const [q,setQ] = useState()
  const [loading,setLoading] = useState()
  const [results,setResults] = useState(null)
  const textInput = useRef(null);
  const searchItem = (props) => {
    // console.log(props);
    return (
      <li key={props.id}>
          <a href={props.url} className="item" target="_blank">
              {props.logo &&
              <div className="imageWrapper">
                  <img src={props.logo} alt="image" className="imaged w64" />
              </div>
              }
              <div className="in">
                  <div>
                      {props.title}
                  </div>
              </div>
          </a>
      </li>
    )
  }  
  const Results = (props) => {
    if(loading) return (<p className="p-2 text-center">搜索中...</p>);
    if(results == null) return (
      <p className="p-2 text-center">搜索资讯、产品、品牌</p>
    );
    if(results == {}) return(
      <p className="p-2 text-center">没有符合条件的结果</p>
    )

    const nodes = Object.entries(results).map((entry) => {
      let name = entry[0];
      let data = entry[1];
      return (
        <div className="section full mb-2" key={name}>
          <div className="wide-block">
          <div className="listview-title">
            {data.title}
            <small>{data.total !== null && data.total}</small>
          </div>
          <ul className="listview image-listview media border-bottom-0">
              { data.data.map(item => searchItem(item))}
          </ul>          
          </div>
        </div>
        )
      }

    )
    return nodes;
  }

  let queue = null;
  useEffect(() =>{
    textInput.current.focus();
    if(typeof(q) == 'undefined') return;
    search()
  },[q])  
  const search = (props) => {
    setLoading(true)
    fetch(API_ROOT + "search?q=" + q)
      .then(response => response.json())
      .then(data => {
        setLoading(false)
        setResults(data.data);
      }); 
  }
  const delayChange = (q) =>{
    if(queue !== null)
      clearTimeout(queue);
    queue = setTimeout(() => setQ(q),1000)
  }
  return (
    <div className="modal fade panelbox panelbox-right" id="SearchPanel" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header show" id="search">
                <form className="search-form" _lpchecked="1">
                    <div className="form-group searchbox">
                        <input 
                          type="text" id="q" className="form-control" placeholder="搜索.." 
                          ref={textInput}
                          disabled={loading ? false : false}
                          onChange={e => delayChange(e.target.value)}
                          autoComplete="off"
                          />
                        <i className="input-icon">
                          <i className="fa fa-search" />
                            {/*<ion-icon name="search-outline" role="img" className="md hydrated" aria-label="search outline"></ion-icon>*/}
                        </i>
                        {loading &&
                        <div className="ml-auto mr-3">
                        <i className="fa fa-spinner fa-pulse" />
                        </div>
                        }
                        <a href="#" className="ms-1 panel-close text-dark pr-3" data-dismiss="modal">
                          <i className="fa fa-times" />
                            {/*<ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>*/}
                        </a>
                    </div>
                </form>              
  {/*                  <input className="form-control" />
                    <a href="#" data-bs-dismiss="modal" className="panel-close">
                      <i className="fa fa-times" />
                    </a>*/}
                </div>
                <div className="modal-body bg-gray">
                  <Results />
                </div>
            </div>
        </div>
    </div>
  )
}
export default SearchPanel;