import React,{useState,useEffect,useRef} from "react"
import ReactDOM from 'react-dom'
import ContentLoader from "react-content-loader"
import { Player, BigPlayButton, LoadingSpinner, ControlBar, ReplayControl,ForwardControl } from 'video-react'
import InfiniteScroll from 'react-infinite-scroller'
import SearchPanel from 'components/search'
import OwlCarousel from 'lib/OwlCarousel'

const MyLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={360}
    height={100}
    viewBox="0 0 360 100"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="48" y="8" rx="3" ry="3" width="88" height="6" /> 
    <rect x="48" y="26" rx="3" ry="3" width="52" height="6" /> 
    <rect x="0" y="56" rx="3" ry="3" width="410" height="6" /> 
    <rect x="0" y="72" rx="3" ry="3" width="380" height="6" /> 
    <rect x="0" y="88" rx="3" ry="3" width="178" height="6" /> 
    <circle cx="20" cy="20" r="20" />
  </ContentLoader>
)
const ProductCard = (props) => (
  <div className="item" key={props.id}>
  <a className="card card-product" href={props.url} target="_blank">
    <img src={props.photo} className="imaged w-100" />
    <div className="card-body p-2">
      <div className="fn fs-12">{props.title}</div>
    </div>

  </a>
  </div>
)
const products_recent = (props) => (
  <div className="section">
  <div className="card card-widget">
    <div className="card-header d-flex align-items-center px-0 pt-0">
      <h3>最新入库</h3>
      <div className="options ml-auto">
        <a href="/chanpin/" className="text-muted">查看更多</a>
      </div>
    </div>
    <div className="card-body p-0 mb-1">
    <OwlCarousel className='owl-theme' loop margin={15} dots={false}>
      {props.map(data => ProductCard(data))}
    </OwlCarousel>
    </div>
  </div>
  </div>
)
const products_recommend = (props) => (
  <div className="section">
  <div className="card card-widget">
    <div className="card-header d-flex align-items-center px-0 pt-0">
      <h3>热门推荐</h3>
      <div className="options ml-auto">
        <a href="/chanpin/" className="text-muted">查看更多</a>
      </div>
    </div>
    <div className="card-body p-0 mb-1">
    <OwlCarousel className='owl-theme' loop margin={15} dots={false}>
      {props.map(data => ProductCard(data))}
    </OwlCarousel>
    </div>
  </div>
  </div>
)


function App(){
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const loaders = [];
  const [cardWidth, setCardWidth] = useState(375 - 32);

  for(let i=0; i<20; i++){
    loaders.push(<div className="p-2" key={["loader-",i].join("")}><MyLoader /></div>);
  }  
  useEffect(() => {
    setCardWidth(window.innerWidth - 32);
  },[])
  const loadFunc = (props) => {
    fetch(API_URL + "?page=" + String(page))
      .then(response => response.json())
      .then(data => {
        if(data.widgets){
          let i = 0;
          let _widgets = Object.entries(data.widgets).filter(([,b]) => b !== null)
          _widgets.map(_widget => {
            i+=8;
            data.stories.splice(i,0,{name: _widget[0],data: _widget[1]});
          })
        }

        setItems([...items,...data.stories]);
        setPage(page + 1);
        if(page > 10)
          setHasMore(false);
      }); 
  }
  // const renderItems = () => (
  const ListPhotos = (props) => {
    if(props.list_photos == null || props.list_photos == []) return;
    // props.list_photos = props.list_photos.slice(0,2);
    return props.list_photos.length == 1 ? (
        <div className="card-cover card-list-photos-one"><img className="imaged w-100" src={props.list_photos[0].url} /></div>
        ) : (
        <div className="card-list-photos">
          <div className={"row no-gutters row-cols-" + props.list_photos.length}>
            {props.list_photos.map((photo,index) => 
              <div className={"col ci-" + index} key={"ci" + index}>
                <img className={"w-100 c-" + index} src={photo.url.replace('fit/800/800/we','fill/405/300/ce')} />
              </div>
             )}

          </div>
        </div>
    )
  }
  const slicePhotos = (photos) =>  {
    if([1,2,3,6].includes(photos.length)) return photos;
    if(photos.length > 6)
      photos = photos.slice(0,6);
    else if(photos.length > 3)
      photos = photos.slice(0,3);
    return photos;
  }
  const TopicPhotos = (props) => {
    if(props.photos == null || props.photos == []) return;
    const photos = slicePhotos(props.photos);
    return photos.length == 1 ? (
        <div className="card-cover-mask card-topic-photo">
          <img 
            className={["imaged",photos[0].orientation == "landscape" ? "w-100" : "img-fluid"].join(" ")} 
            src={photos[0].url.replace('fill/300/300','fit/700/700')} />
        </div>
        ) : (
        <div className="card-topic-photos">
          <div className={["row no-gutters row-cols-" + photos.length,photos.length > 3 ? "cols-more" : ""].join(" ")}>
            {photos.map((photo,index) => 
              <div className={"col-4 ci-" + index} key={"ci-"+index}>
                <img className={"w-100 c-" + index} src={photo.url} />
              </div>
             )}

          </div>
        </div>
    )
  }  

  const ItemCard = (props) => {
    if(['products_recent','products_recommend'].includes(props.name)){
      let fn = eval(props.name);
      return fn(props.data);
    }
    if(!['Article','Daily::Story','Topic','Try::Report'].includes(props.original_type)) return;
    return (
       <div className="section" key={props.id}>
        <div className="card card-story link-card" data-url={props.url}>
          {props.profile_name !== null &&
            <div className="d-flex align-items-center mb-2">
              <img className="imaged w36 rounded mr-2" src={props.profile_logo} />
              <div className="in">
                <div className="fs-14 text-dark">{props.profile_name}</div>
              </div>
              <a className="btn btn-outline-primary ml-auto rounded btn-app-follow fs-12"><span>+</span>关注</a>
            </div>            
          }
          <div className="card-body">
            {!props.tweet &&
            <h3 className="card-title">
            <a target="_blank" href={props.url}>{props.title}</a>
            </h3>
            }
            {props.tweet &&
            <p className="excerpt">
            <a target="_blank" href={props.url}>{props.excerpt}</a>
            </p>
            }
            {0 == 1 &&
            <div className="bg-gray p-1 fs-12 rounded d-flex text-gray mb-2">
            <span className="ml-2">评测品牌: 一任天真</span>
            <span className="ml-3">产品: 盈香 易泡</span>
            </div>   
            }

            {ListPhotos({list_photos: props.list_photos})}
            {TopicPhotos({photos: props.topic_photos})}
            {props.list_photos === null && props.cover &&
            <div className='card-cover'>
              <img className="imaged w-100" src={props.cover} />
            </div>              
            }
            {props.video &&
            <div className="w-100 overflow-hidden video-responsive">
              <Player
                playsInline
                poster={props.poster}
                src={props.video}
                fluid={false}
                width={cardWidth}
                height={cardWidth / 2}
              >
                <BigPlayButton position="center" />
                <LoadingSpinner />
                <ControlBar disableCompletely={true} disableDefaultControls={true}>
                  <ReplayControl seconds={5} order={2.1} />
                  <ForwardControl seconds={5} order={3.1} />
                </ControlBar>
              </Player>          
{/*              <video controls="" width="360" height="200" controlsList="nodownload" poster={props.poster}>
                <source src={props.video} type="video/mp4" />
                您的浏览器不支持视频标签
              </video>*/}
            </div>
            }

                        
          </div>
          <div className="card-footer px-0 py-1 border-0 fs-12 text-muted">
            <span className="mr-1">{props.category}</span>
            <span>{props.comments}评 ·</span>
            <span>{props.votes}赞 ·</span>
            <span>{props.time_ago}</span>
          </div>
        </div>
       </div>
     );
  }
  function renderItems(){
    return items.map(item => ItemCard(item));
  }

  return (
    <div>
    <InfiniteScroll
        pageStart={0}
        loadMore={loadFunc}
        hasMore={hasMore}
        loader={<div className="p-2" key={0}><MyLoader /></div>}
    >
        {renderItems()} 
    </InfiniteScroll>
    <SearchPanel />
    </div>
  )
}


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('app')
);